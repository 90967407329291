.ReactModalPortal-Email .overlayForPrewiev {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	position: fixed;
	background-color: rgba(52, 52, 52, 0.3);
	z-index: 800;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ReactModalPortal-Email .overlayForPrewiev .icon-for-prewiev {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 44px;
	width: 44px;
	right: -1px;
	top: -1px;
	font-size: 22px;
	border-radius: 5px;
	border: 1px solid #cccccc;
	cursor: pointer;
}

.overlayForPrewiev .ReactModal__Content .preview-carousel,
.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap,
.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap .media-carousel,
.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap .media-carousel .media-wrap,
.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap .media-carousel .media-wrap .media-area {
	width: 100%;
	height: 100%;
}

.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap .media-carousel .media-wrap .media-area .carousel-item {
	width: 100%;
	height: auto;
}

.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap .media-carousel .media-wrap .media-area .carousel-item {
	width: 100%;
	height: 100%;
	cursor: default;
}

.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap .media-carousel .nav-next .icon-wrapper,
.overlayForPrewiev .ReactModal__Content .preview-carousel .carousel-wrap .media-carousel .nav-prev .icon-wrapper {
	background: transparent;
	border-left: 0;
	svg {
		color: #cccccc;
		height: 50px;
		width: 50px;
	}
}

.overlayForPrewiev .ReactModal__Content {
	min-width: 320px;
	min-height: 320px;
	width: 60vw;
	height: 80vh;
	position: relative !important;
	border: 1px solid rgb(204, 204, 204);
	background: rgb(255, 255, 255);
	overflow: hidden !important;
	border-radius: 4px;
	outline: none;
	padding: 42px !important;
}

.ReactModal__Content--after-open {
	top: unset !important;
	left: unset !important;
	right: unset !important;
	bottom: unset !important;
}

@media (max-width: 1400px) {
  .overlayForPrewiev .ReactModal__Content {
    height: 80vh;
    width: 80vw;
  }
}

@media (max-width: 1100px) {
  .overlayForPrewiev .ReactModal__Content {
		height: 70vh;
		//margin-top: 24vh;
	}
}

@media (max-height: 1400px) and (max-width: 1100px) {
	.overlayForPrewiev .ReactModal__Content {
		//height: 40vh;
	}
}


@media (max-width: 900px) {
  .overlayForPrewiev .ReactModal__Content {
	  width: 90vw;
	  height: 65vh;
		//margin-left: 10vw;
	}
}

@media (max-width: 750px) {
	.overlayForPrewiev .ReactModal__Content {
		  height: 55vh;
	  }
  }

@media (max-width: 700px) {
  .overlayForPrewiev .ReactModal__Content {
		//width: 100vw;
		//height: 40vh;
		//margin-left: 0vw;
		//margin-top: 30vh;
	}
}

@media (max-width: 600px) {
	.overlayForPrewiev .ReactModal__Content {
		  height: 35vh;
	  }
  }

  @media (max-width: 500px) {
	.overlayForPrewiev .ReactModal__Content {
		  height: 25vh;
		  min-height: 242px;
		  padding: 30px !important;
	  }
  }

@media (max-height: 500px) {
	.overlayForPrewiev .ReactModal__Content {
			//margin-top: 10vh;
		}
}

@media (max-height: 400px) {
	.overlayForPrewiev .ReactModal__Content {
		//margin-top: 5vh;
	}
}

@media screen and (max-height: 500px) and (max-width: 800px) {
	.overlayForPrewiev .ReactModal__Content {
		//   width: 100vw;
		//   height: 50vh;
		//   margin-left: 0vw;
		//margin-top: 10vh;
	}
}

@media screen and (max-height: 450px) and (max-width: 800px) {
	.overlayForPrewiev .ReactModal__Content {
		width: 100vw;
		height: 100vh;
		margin-left: 0vw;
		margin-top: 0vh;
	}
}