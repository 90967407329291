/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.lots-wrapper .top-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .lots-wrapper .top-header .left, .lots-wrapper .top-header .right {
    margin-bottom: 20px; }
  .lots-wrapper .top-header .auction-title {
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 20px 0; }
    @media (max-width: 550px) {
      .lots-wrapper .top-header .auction-title {
        font-size: 28px; } }
  .lots-wrapper .top-header .auction-live-date, .lots-wrapper .top-header .auction-closed-date {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    margin: 0 0 20px 0; }
    @media (max-width: 550px) {
      .lots-wrapper .top-header .auction-live-date, .lots-wrapper .top-header .auction-closed-date {
        font-size: 16px; } }
  .lots-wrapper .top-header .auction-desc {
    margin: 0;
    color: #030404;
    font: 400 16px Lato; }
  .lots-wrapper .top-header .auction-logo img {
    max-width: 250px;
    max-height: 125px; }
  .lots-wrapper .top-header .auction-live-date .status {
    display: inline-block;
    padding-left: 32px; }
    @media (max-width: 605px) {
      .lots-wrapper .top-header .auction-live-date .status {
        display: block;
        padding-left: 0;
        margin-top: 4px; } }
    .lots-wrapper .top-header .auction-live-date .status-open {
      color: #fac917; }
    .lots-wrapper .top-header .auction-live-date .status-live {
      color: #1f9512; }

.lots-wrapper .top-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .lots-wrapper .top-btns.upcoming {
    justify-content: space-between; }
  .lots-wrapper .top-btns .left, .lots-wrapper .top-btns .right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 550px) {
      .lots-wrapper .top-btns .left, .lots-wrapper .top-btns .right {
        width: 100%; } }
  .lots-wrapper .top-btns .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    flex-shrink: 0;
    margin: 0 10px 10px 0; }
    .lots-wrapper .top-btns .btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
    .lots-wrapper .top-btns .btn .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 44px;
      height: 100%;
      font-size: 22px;
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #000000;
      color: #fff; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-green {
        color: #1f9512 !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-success {
        background-color: #1f9512 !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-warning {
        background-color: #fac917 !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-danger {
        color: #dd2d2e !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-red {
        background-color: #dd2d2e !important; }
    @media (max-width: 550px) {
      .lots-wrapper .top-btns .btn {
        margin-right: 0;
        width: 100%; } }
    .lots-wrapper .top-btns .btn.enter-auction-btn {
      color: #fff;
      background-color: #fac917;
      margin-right: 0; }
  .lots-wrapper .top-btns.results {
    border-bottom: 1px solid #c6c6c6; }

.lots-wrapper .table-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 25px 0;
  padding: 40px 25px;
  background-color: #fff;
  border-radius: 5px 5px 0 0; }
  .lots-wrapper .table-wrapper > div,
  .lots-wrapper .table-wrapper > span {
    font-family: Lato;
    font-size: 16px;
    display: flex;
    padding: 5px; }
    .lots-wrapper .table-wrapper > div > div,
    .lots-wrapper .table-wrapper > span > div {
      width: 20%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .lots-wrapper .table-wrapper > div > div:first-child,
    .lots-wrapper .table-wrapper > span > div:first-child {
      width: 60%;
      min-width: 130px !important;
      padding-right: 15px; }
  .lots-wrapper .table-wrapper .table-title {
    background-color: #666666;
    padding: 15px;
    min-width: 640px;
    justify-content: center;
    font-family: Lato;
    font-size: 14px;
    color: #fff;
    margin: 0 5px; }
  .lots-wrapper .table-wrapper .table-header, .lots-wrapper .table-wrapper .table-footer, .lots-wrapper .table-wrapper .table-row {
    font-weight: bold; }
    .lots-wrapper .table-wrapper .table-header div, .lots-wrapper .table-wrapper .table-footer div, .lots-wrapper .table-wrapper .table-row div {
      padding: 15px;
      font-family: Lato;
      font-size: 14px; }
  .lots-wrapper .table-wrapper .table-header div, .lots-wrapper .table-wrapper .table-footer div {
    margin: 1px;
    border: 1px solid #666;
    background-color: #c6c6c6;
    color: #4d4e4e; }
  .lots-wrapper .table-wrapper .table-row {
    padding-top: 0;
    padding-bottom: 0; }
    .lots-wrapper .table-wrapper .table-row.single-row div {
      min-width: 100px;
      border: 1px solid #c6c6c6; }
    .lots-wrapper .table-wrapper .table-row:not(.single-row):first-of-type div {
      border: 1px solid #c6c6c6;
      border-bottom: none; }
    .lots-wrapper .table-wrapper .table-row:not(.single-row):not(:first-of-type) div, .lots-wrapper .table-wrapper .table-row:not(.single-row):not(:last-of-type) div {
      border-left: 1px solid #c6c6c6;
      border-right: 1px solid #c6c6c6; }
    .lots-wrapper .table-wrapper .table-row:not(.single-row):last-of-type div {
      border: 1px solid #c6c6c6;
      border-top: none; }
    .lots-wrapper .table-wrapper .table-row div {
      margin: 0 1px;
      background-color: #ededed;
      color: #030404; }
  .lots-wrapper .table-wrapper.table-auction-results {
    overflow-x: scroll;
    display: inline-flex; }
    .lots-wrapper .table-wrapper.table-auction-results span.table-row div {
      min-width: 100px; }
    @media (max-width: 700px) {
      .lots-wrapper .table-wrapper.table-auction-results {
        padding-left: 0;
        padding-right: 0; } }
    .lots-wrapper .table-wrapper.table-auction-results div.table-header div,
    .lots-wrapper .table-wrapper.table-auction-results div.table-row div,
    .lots-wrapper .table-wrapper.table-auction-results div.table-footer div {
      min-width: 100px; }

.lots-wrapper .lots-controls {
  padding: 10px 0;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6; }
  .lots-wrapper .lots-controls .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center; }
  .lots-wrapper .lots-controls .lots-filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 550px) {
      .lots-wrapper .lots-controls .lots-filters-wrapper {
        flex-direction: row; } }
    @media (min-width: 1250px) {
      .lots-wrapper .lots-controls .lots-filters-wrapper {
        justify-content: space-between; } }
  .lots-wrapper .lots-controls .filter-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding-left: 10px;
    margin-top: 10px; }
    .lots-wrapper .lots-controls .filter-btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
    .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 44px;
      height: 100%;
      font-size: 22px;
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #000000;
      color: #fff; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-green {
        color: #1f9512 !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-success {
        background-color: #1f9512 !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-warning {
        background-color: #fac917 !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-danger {
        color: #dd2d2e !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-red {
        background-color: #dd2d2e !important; }
    .lots-wrapper .lots-controls .filter-btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    @media (max-width: 550px) {
      .lots-wrapper .lots-controls .filter-btn {
        width: 100%; } }
    @media (min-width: 551px) {
      .lots-wrapper .lots-controls .filter-btn:not(:last-of-type) {
        margin-right: 10px; } }
    .lots-wrapper .lots-controls .filter-btn:hover:not(.btn-opened) {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5); }
      .lots-wrapper .lots-controls .filter-btn:hover:not(.btn-opened) .icon-wrapper {
        background-color: #fac917; }
    .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
      transition: 200ms;
      color: #5d5d5d; }
      @media (min-width: 550px) and (max-width: 900px) {
        .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
          margin-left: 10px; } }
    @media (min-width: 550px) {
      .lots-wrapper .lots-controls .filter-btn-sm {
        max-width: 120px; }
      .lots-wrapper .lots-controls .filter-btn-md {
        max-width: 155px; }
      .lots-wrapper .lots-controls .filter-btn-lg {
        max-width: 230px; } }
    .lots-wrapper .lots-controls .filter-btn-active:not(.btn-opened) {
      border-color: #1f9512; }
    .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper,
    .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper {
      background-color: #1f9512;
      border-color: #1f9512; }
      .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper > svg,
      .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper > img,
      .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper > svg,
      .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper > img {
        filter: invert(100) brightness(200); }

.lots-wrapper .catalog-settings {
  display: flex;
  justify-content: space-between;
  margin: 30px 0; }
  .lots-wrapper .catalog-settings > * {
    flex: 1; }
  .lots-wrapper .catalog-settings .catalog-header {
    align-self: flex-end;
    margin: 0;
    font: 400 24px Lato;
    color: #5d5d5d;
    text-transform: uppercase;
    text-align: center; }
  .lots-wrapper .catalog-settings .view-modes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .lots-wrapper .catalog-settings .view-modes .view-mode-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 45px;
      height: 45px;
      background-color: #fff;
      border: 1px solid #464646;
      border-radius: 5px;
      cursor: pointer;
      overflow: hidden; }
      @media (max-width: 900px) {
        .lots-wrapper .catalog-settings .view-modes .view-mode-button {
          display: none; } }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button:not(:last-child) {
        margin-right: 5px; }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button:before {
        content: '';
        width: 100%;
        height: 100%;
        transform: scale(1.1);
        background-size: cover;
        background-position: center; }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button.view-mode-list:before {
        background-image: url("../../../assets/img/view-list.png"); }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button.view-mode-grid:before {
        background-image: url("../../../assets/img/view-grid.png"); }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button.active {
        background-color: #1f9512; }
        .lots-wrapper .catalog-settings .view-modes .view-mode-button.active:before {
          filter: brightness(0) invert(1); }

.lots-list, .watch-lots {
  display: flex;
  flex-direction: column;
  position: relative; }
  .lots-list p, .watch-lots p {
    margin: 0; }
  .lots-list.results .lot-item.lot-item-withdrawn .lot-info-middle, .watch-lots.results .lot-item.lot-item-withdrawn .lot-info-middle {
    background-color: #626262; }
  .lots-list .lot-item, .watch-lots .lot-item {
    display: flex;
    position: relative;
    margin-bottom: 25px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #2e2e2e; }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-top .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-item-top .lot-info .lot-info-bottom {
        justify-content: start;
        align-items: start; } }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-top .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-item-top .lot-info .lot-info-bottom .info-wrap {
        justify-content: start;
        align-items: start; } }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom {
        align-items: start; } }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-wrap {
        justify-content: start; } }
    .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns {
      width: 241px;
      justify-content: flex-end; }
      .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child {
        margin-bottom: 5px;
        margin-right: 0 !important; }
        @media (max-width: 550px) {
          .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child {
            margin-bottom: 0; } }
    .lots-list .lot-item.dump, .watch-lots .lot-item.dump {
      border: 0;
      box-shadow: none;
      background-color: transparent; }
    .lots-list .lot-item.lot-item-withdrawn, .watch-lots .lot-item.lot-item-withdrawn {
      background-color: #626262; }
      .lots-list .lot-item.lot-item-withdrawn .lot-info, .watch-lots .lot-item.lot-item-withdrawn .lot-info {
        background-color: #cccccc; }
        .lots-list .lot-item.lot-item-withdrawn .lot-info .lot-info-middle, .watch-lots .lot-item.lot-item-withdrawn .lot-info .lot-info-middle {
          min-height: auto;
          border-bottom: 0; }
          .lots-list .lot-item.lot-item-withdrawn .lot-info .lot-info-middle .lot-title, .watch-lots .lot-item.lot-item-withdrawn .lot-info .lot-info-middle .lot-title {
            text-decoration: line-through;
            margin: 0; }
    .lots-list .lot-item.existing, .watch-lots .lot-item.existing {
      display: flex;
      flex: 1;
      flex-direction: column; }
      .lots-list .lot-item.existing .lot-item-top, .watch-lots .lot-item.existing .lot-item-top {
        flex: 1;
        display: flex;
        position: relative; }
      .lots-list .lot-item.existing .lot-item-bottom, .watch-lots .lot-item.existing .lot-item-bottom {
        flex: 1;
        border-top: 1px solid #c6c6c6; }
    .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      width: 300px; }
      @media (max-width: 900px) {
        .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
          width: 100%; } }
    .lots-list .lot-item .lot-info-top, .watch-lots .lot-item .lot-info-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 20px;
      color: #fff;
      background-color: #2e2e2e;
      font: 100 20px Lato; }
      .lots-list .lot-item .lot-info-top .lot-number .my-listing, .watch-lots .lot-item .lot-info-top .lot-number .my-listing {
        padding-left: 5px; }
        .lots-list .lot-item .lot-info-top .lot-number .my-listing .icon-wrapper, .watch-lots .lot-item .lot-info-top .lot-number .my-listing .icon-wrapper {
          position: relative;
          top: -2px;
          padding: 0 5px; }
      .lots-list .lot-item .lot-info-top .lot-location, .watch-lots .lot-item .lot-info-top .lot-location {
        text-transform: uppercase; }
    .lots-list .lot-item .lot-info, .watch-lots .lot-item .lot-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-family: Lato; }
      .lots-list .lot-item .lot-info .lot-info-middle, .watch-lots .lot-item .lot-info .lot-info-middle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0;
        min-height: 100px;
        padding: 5px 10px 8px 20px;
        border-bottom: 1px solid #c6c6c6; }
        @media (min-width: 550px) {
          .lots-list .lot-item .lot-info .lot-info-middle, .watch-lots .lot-item .lot-info .lot-info-middle {
            flex-direction: row; } }
        .lots-list .lot-item .lot-info .lot-info-middle .left, .watch-lots .lot-item .lot-info .lot-info-middle .left {
          padding-right: 20px;
          max-width: fit-content; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-title-wrapper, .watch-lots .lot-item .lot-info .lot-info-middle .lot-title-wrapper {
          display: flex;
          justify-content: space-between;
          width: 275px;
          margin-bottom: 16px; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-sub-title, .watch-lots .lot-item .lot-info .lot-info-middle .lot-sub-title {
          min-width: 125px;
          color: #2e2e2e;
          font-size: 18px;
          margin: 8px 0 8px; }
        .lots-list .lot-item .lot-info .lot-info-middle .curfew, .watch-lots .lot-item .lot-info .lot-info-middle .curfew {
          color: #e6bd2f; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-title, .watch-lots .lot-item .lot-info .lot-info-middle .lot-title {
          color: #2e2e2e;
          font-size: 24px;
          margin: 16px 0 28px; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state {
          font-family: Lato;
          font-size: 24px;
          font-weight: 100;
          color: #dd2d2e;
          /* margin-bottom: 25px; */ }
          .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.lot-closed-success, .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.sold, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.lot-closed-success, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.sold {
            color: #1f9512; }
          .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.passed, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.passed {
            color: #f9891a; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-comments, .watch-lots .lot-item .lot-info .lot-info-middle .lot-comments {
          word-break: break-word;
          margin-top: 15px;
          color: #696969;
          font-size: 12px; }
        .lots-list .lot-item .lot-info .lot-info-middle .info-btns, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns {
          margin-bottom: 10px; }
          .lots-list .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child), .watch-lots .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child) {
            margin-bottom: 10px; }
          .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 231px;
            height: 44px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #000000;
            text-align: left;
            color: #030404;
            font-family: Lato;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            transition: box-shadow 300ms;
            padding-left: 20px;
            text-decoration: none;
            overflow: hidden;
            user-select: none; }
            .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn:hover, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn:hover {
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
            .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0 0 44px;
              height: 100%;
              font-size: 22px;
              border-radius: 5px 0 0 5px;
              border-left: 1px solid #000000;
              color: #fff; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-green, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-green {
                color: #1f9512 !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-success, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-success {
                background-color: #1f9512 !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-warning, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-warning {
                background-color: #fac917 !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-danger, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-danger {
                color: #dd2d2e !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-red, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-red {
                background-color: #dd2d2e !important; }
      .lots-list .lot-item .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-info .lot-info-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px; }
        @media (min-width: 550px) {
          .lots-list .lot-item .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-info .lot-info-bottom {
            flex-direction: row; } }
        .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
          display: flex;
          justify-content: center;
          flex-wrap: wrap; }
          @media (min-width: 550px) {
            .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
              justify-content: flex-start; } }
          .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span {
            display: inline-block;
            line-height: 35px;
            padding: 0 7px;
            border-radius: 5px;
            border: 1px solid #5d5d5d;
            color: #5d5d5d;
            font-family: Lato;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 5px; }
            @media (min-width: 1250px) {
              .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span {
                margin-bottom: 0;
                justify-content: flex-start; } }
            .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span:not(:last-child), .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span:not(:last-child) {
              margin-right: 5px; }
        .lots-list .lot-item .lot-info .lot-info-bottom .company-logo-wrapper img, .watch-lots .lot-item .lot-info .lot-info-bottom .company-logo-wrapper img {
          max-height: 50px; }

@media (min-width: 900px) {
  .lots-list.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    .lots-list.grid .lot-item {
      display: flex;
      flex-direction: column;
      width: 32%; }
      .lots-list.grid .lot-item .lot-img-container {
        width: 100%;
        height: 250px; }
      .lots-list.grid .lot-item .lot-info .lot-info-middle {
        padding: 12px 20px;
        flex-direction: column; }
        .lots-list.grid .lot-item .lot-info .lot-info-middle .left {
          padding: 0; }
          .lots-list.grid .lot-item .lot-info .lot-info-middle .left .lot-title {
            text-align: center; }
        .lots-list.grid .lot-item .lot-info .lot-info-middle .info-btns {
          display: flex;
          flex-direction: row;
          margin: 10px 0; }
          .lots-list.grid .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: 5px; }
      .lots-list.grid .lot-item .lot-info .lot-info-bottom {
        flex-direction: column;
        padding: 0; }
        .lots-list.grid .lot-item .lot-info .lot-info-bottom .info-wrap {
          width: 100%;
          justify-content: center;
          padding: 15px 20px 10px 20px;
          border-bottom: 1px solid #c6c6c6; }
          .lots-list.grid .lot-item .lot-info .lot-info-bottom .info-wrap span {
            margin-bottom: 5px; }
        .lots-list.grid .lot-item .lot-info .lot-info-bottom .company-logo-wrapper {
          padding: 16px 0; } }

@media (max-width: 1250px) and (min-width: 900px) {
  .lots-list.grid .lot-item {
    width: 49%; }
  .lots-list.grid.dump {
    display: none; } }

@media (max-width: 900px) {
  .lots-wrapper .catalog-settings {
    flex-direction: column; }
    .lots-wrapper .catalog-settings .catalog-header {
      margin-top: 10px;
      align-self: center; }
  .lots-list .lot-item, .watch-lots .lot-item {
    display: block; }
    .lots-list .lot-item.existing .lot-item-top, .watch-lots .lot-item.existing .lot-item-top {
      display: block; }
    .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
      height: 270px; }
      .lots-list .lot-item .lot-img-container-withdrawn, .watch-lots .lot-item .lot-img-container-withdrawn {
        height: 100px; } }
