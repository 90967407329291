// header
$header-height: 75px;

// navbar
$navbar-height: 50px;
$nav-active-bg: #5d5d5d;

// Upcoming...
$gray-border: #c6c6c6;

//common colors
$light-background: #cccccc;
$middle-dark-text-color: #8e8c8c;
$watchlist-color: #00cc33;
$watchlist-color-gold: #ffd700;
$grey-text-color: #666;
$main-text-color: #333;
$light-text-color: #cccccc;
$due-color: #ff0000;
$paid-color: #00ff00;
$live-color: #ffa500;
$future-color: #666;
$link-color: #2996cc;
$dark-green: #1f9512;
$text-color: #030404;

$default-font: 'Lato';

//text
$regular-text: 400 16px 'Lato';
$bold-text: 700 16px 'Lato';

$medium-width: 900px;
$mobile-width: 550px;
$mobile-width-min: 551px;
$large-width: 1250px;

// footer
$footer-height: 75px;

// live auction
$live-header-height: 110px;
$live-navbar-height: 55px;
$live-notification-height: 80px;
$live-sidebar-width: 430px;
$live-fixed-top: $live-header-height + $live-navbar-height + 5px;
$live-fixed-top-with-notification: $live-fixed-top + $live-notification-height;

@mixin checkboxes {
  height: 44px;
  width: 44px;
  border-radius: 5px;
  background-color: #c6c6c6;
}
@mixin true {
  height: 19px;
  width: 24px;
  margin: 13px auto 13px auto;
  background-color: #c6c6c6;
  background-image: url('../assets/img/black-icon-tick.png');
}
@mixin black-title {
  color: #000000;
  font-family: Lato;
  font-size: 30px;
  font-weight: 400;
}
@mixin gray-title {
  color: #5d5d5d;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
}
@mixin basic-button-old {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 31px;
  font-family: 'Myriad Pro';
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
  transition: background-color 0.2s;
  margin: 0;
  user-select: none;
  text-decoration: none;
}
@mixin basic-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 231px;
  height: 44px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #000000;
  text-align: left;
  color: #030404;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: box-shadow 300ms;
  padding-left: 20px;
  text-decoration: none;
  overflow: hidden;
  user-select: none;

  &:hover {
    box-shadow: 0 0 5px rgba(0,0,0,0.16), 0 0 5px rgba(0,0,0,0.3);
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;

    &.icon-green {
      color:  #1f9512 !important;
    }
    &.icon-success {
      background-color: #1f9512 !important;
    }
    &.icon-warning {
      background-color: #fac917 !important;
    }
    &.icon-danger {
      color: #dd2d2e !important;
    }
    &.icon-red {
      background-color: #dd2d2e !important;
    }
  }
}
@mixin reverse-button {
  padding-left: 0;
  padding-right: 20px;
  .icon-wrapper {
    border-left: 0;
    border-right: 1px solid #000000;
    border-radius: 0 5px 5px 0;
  }
}
@mixin filter-button {
  margin: 0;
  padding-left: 10px;

  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: $mobile-width) {
    width: 100%;
  }
  @media (min-width: $mobile-width-min) {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &:hover:not(.btn-opened) {
    box-shadow: 0 0 5px rgba(0,0,0,0.16), 0 0 5px rgba(0,0,0,0.5);

    .icon-wrapper {
      background-color: #fac917;
    }
  }

  .icon-wrapper {
    transition: 200ms;
    color: #5d5d5d;

    @media (min-width: $mobile-width) and (max-width: $medium-width) {
      margin-left: 10px;
    }
  }

  @media (min-width: $mobile-width) {
    &-sm {
      max-width: 120px;
    }

    &-md {
      max-width: 155px;
    }

    &-lg {
      max-width: 230px;
    }
  }

  &-active {

    &:not(.btn-opened) {
      border-color: #1f9512;
    }

    .icon-wrapper,
    &:hover .icon-wrapper {
      background-color: #1f9512;
      border-color: #1f9512;

      & > svg,
      & > img {
        filter: invert(100) brightness(200);
      }
    }
  }
}

@mixin auth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 12px 15px;
  border-radius: 2px;
  background-color: #1f9512;
  border: none;
  color: #ffffff;
  font: 400 18px Lato;
  text-transform: uppercase;
  transition: background-color .2s linear;
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: rgba(37, 178, 21, 1);
  }
}
/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
@mixin flex-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: stretch;
}

@mixin auction-title-header {
  color: #030404;
  font-family: Lato;
  font-size: 40px;
  font-weight: 400;
  margin: 0 0 20px 0;

  @media (max-width: $mobile-width) {
    font-size: 28px;
  }
}

@mixin auction-title-sub-header {
  color: #030404;
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  margin: 0 0 20px 0;
  line-height: 26px;
}

@mixin date-header {
  color: #5d5d5d;
  font-family: Lato;
  font-size: 24px;
  font-weight: 400;
  margin: 0;

  @media (max-width: $mobile-width) {
    font-size: 16px;
  }
}

/* header at the top that is used in a number of screens
 used to display basic Auction data */
@mixin top-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .left, .right {
    margin-bottom: 20px;
  }
  .auction-title {
    @include auction-title-header;
  }
  .auction-live-date, .auction-closed-date {
    @include date-header;
    margin: 0 0 20px 0;
  }
  .auction-desc {
    margin: 0;
    color: #030404;
    font: 400 16px Lato;
  }
  .auction-logo {
    img {
      max-width: 250px;
      max-height: 125px;
    }
  }
}

@mixin header-notification {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  background: red;
  color: white;
  font-family: Lato;
  font-size: 10px;
}

@mixin section {
  border-radius: 5px;
  overflow: hidden;
  font-family: Lato;
  .title {
    background: #b0aeaf;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 35px;
    font-size: 23px;
  }
  .body {
    background: #ffffff;
    padding: 20px;
  }
}
