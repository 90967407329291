@import './general/variables.scss';

.filter-date-wrapper,
.filter-multi-wrapper,
.filter-range-wrapper {
  width: 100%;
  position: relative;

  @media (min-width: $mobile-width-min) {
    max-width: 231px;
    margin-right: 10px;
  }

  .btn-opened {
    position: relative;
    z-index: 900;
    border-color: #000;
    border-radius: 5px;

    @media (min-width: $mobile-width-min) {
      border-bottom: 0;
      border-radius: 5px 5px 5px 0;
    }

    .icon-wrapper {
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
    }
  }
}

.filter-date-inner,
.filter-multi-inner,
.filter-range-inner {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 15px;
  background-color: #fff;
  font-family: Lato;
  border-radius: 5px;
  border: 1px solid #000;
  z-index: 99;

  @media (min-width: $mobile-width-min) {
    width: auto;
  }

  @media (min-width: $large-width) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > div:first-child {
    text-align: center;

    @media (min-width: $large-width) {
      text-align: left;
    }
  }

  &.calendars-right-aligned {
    left: auto;
    right: 0;

    @media (min-width: $mobile-width-min) {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      right: 1px;
    }
  }

  h3, p {
    color: #5d5d5d;
    margin: 0;
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
  }

  p {
    font-size: 14px;
  }

  .calendars-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: $mobile-width-min) {
      flex-direction: row;
      align-items: flex-start;
    }

    &.in-column {
      flex-direction: column;
    }
  }
}

.calendars-buttons,
.range-buttons {
  display: flex;
  justify-content: center;

  .btn:first-child {
    margin-right: 10px;
  }
}

@media (min-width: $large-width) {
  .filter-date-inner {
    width: 873px;
  }
}


.calendars-controls {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 14px;
  color: #5d5d5d;
}

.calendars-controls,
.filter-multi-options {

  label {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .filter-checkbox {
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #000;

    &.active {
      background-color: #1f9512;
    }
  }

  input {
    display: none;
  }
}

.DayPicker-wrapper {
  font-family: Lato;
  .DayPicker-Body {
    .DayPicker-Week {
      .DayPicker-Day[role=gridcell] {
        .DayPicker-Filled {
          border: 1px solid #464646;;
          border-right: 0;
          border-bottom: 0;
        }

        &:last-of-type {
          .DayPicker-Filled {
            border-right: 1px solid #464646;
          }
        }
      }
      &:first-child {
        .DayPicker-Day:not([role="gridcell"]) + .DayPicker-Day {
          .DayPicker-Filled {
            border-top-left-radius: 5px;
          }
        }
        .DayPicker-Day[role="gridcell"] {
          &:first-of-type {
            .DayPicker-Filled {
              border-top-left-radius: 5px;
            }
          }
          &:last-of-type {
            .DayPicker-Filled {
              border-right: 1px solid #464646;
              border-top-right-radius: 5px;
            }
          }
        }
      }
      &:last-child {
        .DayPicker-Day[role="gridcell"] {
          .DayPicker-Filled {
            border-bottom: 1px solid #464646;
          }
          &:first-of-type {
            .DayPicker-Filled {
              border-bottom-left-radius: 5px;
            }
          }
          &:last-of-type {
            .DayPicker-Filled {
              border-right: 1px solid #464646;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }

      .DayPicker-Day[role="gridcell"] {
        position: relative;
        padding: 0;
        height: 33px;
        width: 33px;

        .DayPicker-Filled {
          display: flex;
          width: 104%;
          height: 102%;
          justify-content: center;
          align-items: center;
          margin: 0;
        }
      }

      .DayPicker-Day--today {
        font-weight: inherit;
        &:not(.DayPicker-Day--disabled) {
          color: inherit;
        }
      }

      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover .DayPicker-Filled {
        background-color: #fac917;
      }

      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) .DayPicker-Filled {
        background-color: #1f9512;
        color: #fff;
      }
    }
  }
}

.DayPicker-Caption {

  div {
    text-align: center;
  }
}

.DayPicker-wrapper .DayPicker-NavButton {
  height: 30px;
  width: 30px;
  top: 8px;
  border-radius: 3px;
  border: 1px solid #000;
  background-color: #c6c6c6;
  color: #fff;

  &--prev {
    left: 1rem;
    right: auto;
    margin-right: 0;
    background-image: url(./assets/img/arrow-left.png)
  }

  &--next {
    right: 1rem;
    background-image: url(./assets/img/arrow-right.png)
  }
}
