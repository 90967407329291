/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
@import url(../node_modules/react-dropdown/style.css);
@font-face {
  font-family: 'Myriad Pro';
  src: url("./assets/fonts/MyriadPro-Regular.otf");
  font-weight: 400; }

@font-face {
  font-family: 'Myriad Pro';
  src: url("./assets/fonts/MyriadPro-Bold.otf");
  font-weight: 700; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Thin.woff2");
  font-weight: 100; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Regular.woff2");
  font-weight: 400; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Bold.woff2");
  font-weight: 700; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Black.woff2");
  font-weight: 900; }

* {
  box-sizing: border-box;
  outline: none; }

html, body {
  margin: 0;
  height: 100%; }
  html #root, body #root {
    height: 100%; }
    html #root > div, body #root > div {
      position: relative;
      height: 100%; }

body {
  min-width: 320px; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
html {
  height: 100%; }

body {
  min-height: 100%; }

.inner-container {
  max-width: 1280px;
  position: relative;
  background-color: white;
  width: 100%;
  min-height: 1020px;
  margin: auto;
  background-color: #ededed;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
  padding: 40px 50px; }
  @media (max-width: 900px) {
    .inner-container {
      padding: 25px 10px; } }

.body-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 40px 5px;
  background-color: #cccccc;
  clear: both; }
  .body-container .inner-container {
    position: relative;
    background-color: white;
    max-width: 1280px;
    width: 100%;
    min-height: 1020px;
    margin: auto;
    background-color: #ededed;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    padding: 40px 50px; }
    @media (max-width: 900px) {
      .body-container .inner-container {
        padding: 25px 10px; } }
  @media (max-width: 900px) {
    .body-container {
      padding: 20px 0; } }

.no-image {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: grey;
  width: 100%;
  height: 100%;
  font: normal 16px 'Lato';
  color: #fff; }
  .no-image:before {
    content: '';
    background: url("./assets/img/icon_photo.png") no-repeat;
    background-size: cover;
    display: flex;
    width: 53px;
    height: 40px;
    margin-bottom: 10px; }
  .no-image.cattle:before {
    background: url("./assets/img/cow.png") no-repeat; }
  .no-image.sheep:before {
    background: url("./assets/img/sheep.png") no-repeat; }
  .no-image.goat:before {
    background: url("./assets/img/goat-image-middle.png") no-repeat; }

.separator {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #c6c6c6;
  margin: 0; }

.app-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7); }
  .app-spinner-overlay.area {
    position: absolute; }
  .app-spinner-overlay .app-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .app-spinner-overlay .app-spinner.area {
      position: absolute; }

.remove {
  width: 20px;
  height: 20px;
  background: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer; }
  .remove:after {
    width: 20px;
    height: 20px;
    content: '';
    background: url("./assets/img/closebutton.png") no-repeat;
    background-size: cover;
    display: block; }

button:focus, label:focus {
  box-shadow: 0 0 2px 2px #a3a3b1; }

.disabled {
  opacity: 0.6;
  pointer-events: none; }

.fg-header {
  color: #030404;
  font-family: Lato;
  font-size: 40px;
  font-weight: 400;
  margin: 0 0 20px 0; }
  @media (max-width: 550px) {
    .fg-header {
      font-size: 28px; } }

.fg-sub-header {
  color: #030404;
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  margin: 0 0 20px 0;
  line-height: 26px; }

.fg-paragraph {
  color: #030404;
  font: 400 16px Lato; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.app-route {
  position: relative;
  z-index: 3; }

.no-data-placeholder {
  margin-top: 20px;
  text-align: center; }
  .no-data-placeholder .message {
    display: flex;
    justify-content: center;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400; }

.ReactModalPortal-Email .overlay {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-height: 500px) {
    .ReactModalPortal-Email .overlay {
      overflow-y: scroll; } }
  .ReactModalPortal-Email .overlay .content {
    display: flex;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    width: 60%;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cccccc;
    background: white;
    overflow: hidden;
    outline: none;
    font-family: 'Lato'; }
    @media (max-width: 500px) {
      .ReactModalPortal-Email .overlay .content {
        width: 90%; } }
    @media (min-width: 1200px) {
      .ReactModalPortal-Email .overlay .content {
        max-width: 700px; } }
    @media (max-height: 500px) {
      .ReactModalPortal-Email .overlay .content {
        margin-top: 90px;
        margin-bottom: 30px; } }
    @media (max-height: 350px) {
      .ReactModalPortal-Email .overlay .content {
        margin-top: 150px;
        margin-bottom: 30px; } }
    @media (max-height: 390px) {
      .ReactModalPortal-Email .overlay .content {
        max-height: 420px; } }
    .ReactModalPortal-Email .overlay .content .icon-danger {
      color: #dd2d2e; }
    .ReactModalPortal-Email .overlay .content .icon-close {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 44px;
      right: -1px;
      top: -1px;
      font-size: 22px;
      border-radius: 5px;
      border: 1px solid #cccccc;
      cursor: pointer; }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .icon-close {
          height: 30px;
          width: 30px; } }
    .ReactModalPortal-Email .overlay .content .modal-header {
      text-align: center; }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .modal-header {
          font-size: 18px; } }
    .ReactModalPortal-Email .overlay .content .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      width: 60%; }
      @media (max-width: 450px) {
        .ReactModalPortal-Email .overlay .content .modal-body {
          margin-top: 0; } }
      @media (max-height: 390px) {
        .ReactModalPortal-Email .overlay .content .modal-body {
          margin-top: 0; } }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .modal-body {
          width: 100%; } }
      .ReactModalPortal-Email .overlay .content .modal-body.download {
        margin-top: 0; }
        @media (max-width: 800px) {
          .ReactModalPortal-Email .overlay .content .modal-body.download {
            width: 100%; } }
      .ReactModalPortal-Email .overlay .content .modal-body form {
        width: 100%; }
    .ReactModalPortal-Email .overlay .content .modal-input ~ .modal-input {
      margin-top: 30px; }
    .ReactModalPortal-Email .overlay .content .modal-input {
      display: block;
      width: 100%;
      min-height: 45px;
      border-radius: 5px;
      background-color: #666666;
      color: #ffffff;
      font: 100 18px Lato;
      line-height: 30px;
      transition: background-color .5s ease;
      padding-left: 15px;
      border: none; }
      .ReactModalPortal-Email .overlay .content .modal-input:focus {
        background-color: rgba(102, 102, 102, 0.8); }
      .ReactModalPortal-Email .overlay .content .modal-input::placeholder {
        color: #ffffff; }
    .ReactModalPortal-Email .overlay .content .choice-desc {
      text-align: left; }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .choice-desc {
          margin-bottom: 0;
          font-size: 14px; } }
      @media (max-width: 400px) {
        .ReactModalPortal-Email .overlay .content .choice-desc {
          max-width: 250px;
          margin-top: 20px; } }
    .ReactModalPortal-Email .overlay .content .buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 50px; }
      @media (max-width: 450px) {
        .ReactModalPortal-Email .overlay .content .buttons {
          margin-bottom: 0; } }
      @media (min-width: 1348px) {
        .ReactModalPortal-Email .overlay .content .buttons .button {
          flex: 1 1;
          min-width: 213px !important; } }
      .ReactModalPortal-Email .overlay .content .buttons .button {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 231px;
        height: 44px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #000000;
        text-align: left;
        color: #030404;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        transition: box-shadow 300ms;
        padding-left: 20px;
        text-decoration: none;
        overflow: hidden;
        user-select: none;
        margin-top: 30px;
        width: 100%;
        border-radius: 3px;
        color: #ffffff;
        background-color: #1f9512; }
        .ReactModalPortal-Email .overlay .content .buttons .button:hover {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
        .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 44px;
          height: 100%;
          font-size: 22px;
          border-radius: 5px 0 0 5px;
          border-left: 1px solid #000000;
          color: #fff; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-green {
            color: #1f9512 !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-success {
            background-color: #1f9512 !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-warning {
            background-color: #fac917 !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-danger {
            color: #dd2d2e !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-red {
            background-color: #dd2d2e !important; }
        .ReactModalPortal-Email .overlay .content .buttons .button.red-button {
          background-color: #dd2d2e; }
        .ReactModalPortal-Email .overlay .content .buttons .button .button-text {
          color: #ffffff;
          font-family: Lato;
          font-size: 14px;
          font-weight: 400;
          padding-right: 20px; }
        @media (max-height: 390px) {
          .ReactModalPortal-Email .overlay .content .buttons .button {
            margin-top: 10px; } }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.auctions-wrapper {
  position: relative;
  background-color: #ededed; }
  .auctions-wrapper .red {
    color: red; }
  .auctions-wrapper .auctions-list {
    margin-top: 20px; }
    .auctions-wrapper .auctions-list .auctions-group .group-title {
      color: #000000;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      font-weight: normal;
      font-size: 25px;
      text-align: center;
      margin-bottom: 25px;
      color: #5d5d5d; }
    .auctions-wrapper .auctions-list .auctions-empty {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      margin-top: 60px;
      color: #ADB5BD; }
      .auctions-wrapper .auctions-list .auctions-empty svg {
        font-size: 60px; }
      .auctions-wrapper .auctions-list .auctions-empty p {
        font-size: 30px;
        margin-top: 10px;
        font-weight: 400; }
    .auctions-wrapper .auctions-list .locked-item {
      position: relative;
      overflow: hidden;
      pointer-events: none;
      cursor: none;
      margin-bottom: 25px; }
      .auctions-wrapper .auctions-list .locked-item .result {
        position: absolute;
        width: 117%;
        left: -8%;
        height: 125px;
        font-size: 40px;
        font-weight: 300;
        z-index: 10;
        top: calc(50% - 65px);
        transform: rotate(-16deg);
        background: rgba(22, 22, 22, 0.4);
        color: #ffffff;
        text-transform: uppercase;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato; }
      .auctions-wrapper .auctions-list .locked-item .auction-item {
        margin: 0;
        filter: brightness(80%); }
    .auctions-wrapper .auctions-list .auction-item {
      display: flex;
      position: relative;
      border: 1px solid #c6c6c6;
      margin-bottom: 25px;
      border-radius: 5px;
      background-color: #ffffff;
      overflow: hidden; }
      .auctions-wrapper .auctions-list .auction-item .auction-img-container {
        min-width: 320px;
        max-width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-right: 1px solid #c6c6c6; }
        .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper {
          height: 100%;
          width: 100%;
          display: flex;
          padding: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fac917; }
          .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper > img {
            filter: brightness(0.01) invert(100%); }
          .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn {
            color: #fff;
            font-family: Lato;
            font-size: 24px;
            font-weight: 400;
            border-color: #fff;
            margin-bottom: 0;
            background-color: transparent;
            padding: 0; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn span:first-child {
              margin: 0 auto; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn .icon-wrapper {
              border-color: #fff; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn:hover {
              box-shadow: 0 0 5px rgba(255, 255, 255, 0.16), 0 0 5px rgba(255, 255, 255, 0.3); }
          .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper-open {
            background-color: #535353; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper-open .enter-auction-btn {
              font-size: 20px; }
        .auctions-wrapper .auctions-list .auction-item .auction-img-container .auction-img {
          max-width: 100%;
          max-height: 100%;
          user-select: none; }
        .auctions-wrapper .auctions-list .auction-item .auction-img-container .no-image {
          min-height: 300px; }
      .auctions-wrapper .auctions-list .auction-item .auction-live-info {
        text-transform: uppercase;
        color: #1f9512;
        font-family: Lato;
        font-size: 20px;
        font-weight: 700; }
  .auctions-wrapper .scroll-loader-wrap {
    visibility: hidden;
    text-align: center; }
    .auctions-wrapper .scroll-loader-wrap.active {
      visibility: visible; }
    .auctions-wrapper .scroll-loader-wrap .scroll-loader {
      display: inline-block; }

.auctions-wrapper .btn, .watchlist-wrapper .btn, .submitted-wrapper .btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 231px;
  height: 44px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #000000;
  text-align: left;
  color: #030404;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: box-shadow 300ms;
  padding-left: 20px;
  text-decoration: none;
  overflow: hidden;
  user-select: none; }
  .auctions-wrapper .btn:hover, .watchlist-wrapper .btn:hover, .submitted-wrapper .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
  .auctions-wrapper .btn .icon-wrapper, .watchlist-wrapper .btn .icon-wrapper, .submitted-wrapper .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff; }
    .auctions-wrapper .btn .icon-wrapper.icon-green, .watchlist-wrapper .btn .icon-wrapper.icon-green, .submitted-wrapper .btn .icon-wrapper.icon-green {
      color: #1f9512 !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-success, .watchlist-wrapper .btn .icon-wrapper.icon-success, .submitted-wrapper .btn .icon-wrapper.icon-success {
      background-color: #1f9512 !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-warning, .watchlist-wrapper .btn .icon-wrapper.icon-warning, .submitted-wrapper .btn .icon-wrapper.icon-warning {
      background-color: #fac917 !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-danger, .watchlist-wrapper .btn .icon-wrapper.icon-danger, .submitted-wrapper .btn .icon-wrapper.icon-danger {
      color: #dd2d2e !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-red, .watchlist-wrapper .btn .icon-wrapper.icon-red, .submitted-wrapper .btn .icon-wrapper.icon-red {
      background-color: #dd2d2e !important; }
  .auctions-wrapper .btn:not(:first-of-type), .watchlist-wrapper .btn:not(:first-of-type), .submitted-wrapper .btn:not(:first-of-type) {
    margin-top: 10px; }
  .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
    margin: 0;
    padding-left: 10px;
    width: 231px; }
    .auctions-wrapper .btn.filter-btn:hover, .watchlist-wrapper .btn.filter-btn:hover, .submitted-wrapper .btn.filter-btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    @media (max-width: 550px) {
      .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
        width: 100%; } }
    @media (min-width: 551px) {
      .auctions-wrapper .btn.filter-btn:not(:last-of-type), .watchlist-wrapper .btn.filter-btn:not(:last-of-type), .submitted-wrapper .btn.filter-btn:not(:last-of-type) {
        margin-right: 10px; } }
    .auctions-wrapper .btn.filter-btn:hover:not(.btn-opened), .watchlist-wrapper .btn.filter-btn:hover:not(.btn-opened), .submitted-wrapper .btn.filter-btn:hover:not(.btn-opened) {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5); }
      .auctions-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper, .watchlist-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper, .submitted-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper {
        background-color: #fac917; }
    .auctions-wrapper .btn.filter-btn .icon-wrapper, .watchlist-wrapper .btn.filter-btn .icon-wrapper, .submitted-wrapper .btn.filter-btn .icon-wrapper {
      transition: 200ms;
      color: #5d5d5d; }
      @media (min-width: 550px) and (max-width: 900px) {
        .auctions-wrapper .btn.filter-btn .icon-wrapper, .watchlist-wrapper .btn.filter-btn .icon-wrapper, .submitted-wrapper .btn.filter-btn .icon-wrapper {
          margin-left: 10px; } }
    @media (min-width: 550px) {
      .auctions-wrapper .btn.filter-btn-sm, .watchlist-wrapper .btn.filter-btn-sm, .submitted-wrapper .btn.filter-btn-sm {
        max-width: 120px; }
      .auctions-wrapper .btn.filter-btn-md, .watchlist-wrapper .btn.filter-btn-md, .submitted-wrapper .btn.filter-btn-md {
        max-width: 155px; }
      .auctions-wrapper .btn.filter-btn-lg, .watchlist-wrapper .btn.filter-btn-lg, .submitted-wrapper .btn.filter-btn-lg {
        max-width: 230px; } }
    .auctions-wrapper .btn.filter-btn-active:not(.btn-opened), .watchlist-wrapper .btn.filter-btn-active:not(.btn-opened), .submitted-wrapper .btn.filter-btn-active:not(.btn-opened) {
      border-color: #1f9512; }
    .auctions-wrapper .btn.filter-btn-active .icon-wrapper,
    .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper, .watchlist-wrapper .btn.filter-btn-active .icon-wrapper,
    .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper, .submitted-wrapper .btn.filter-btn-active .icon-wrapper,
    .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper {
      background-color: #1f9512;
      border-color: #1f9512; }
      .auctions-wrapper .btn.filter-btn-active .icon-wrapper > svg,
      .auctions-wrapper .btn.filter-btn-active .icon-wrapper > img,
      .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg,
      .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper > img, .watchlist-wrapper .btn.filter-btn-active .icon-wrapper > svg,
      .watchlist-wrapper .btn.filter-btn-active .icon-wrapper > img,
      .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg,
      .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper > img, .submitted-wrapper .btn.filter-btn-active .icon-wrapper > svg,
      .submitted-wrapper .btn.filter-btn-active .icon-wrapper > img,
      .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg,
      .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper > img {
        filter: invert(100) brightness(200); }
    @media (max-width: 550px) {
      .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
        width: 100%;
        max-width: 100%; } }

@media (max-width: 550px) {
  .submitted-wrapper .watch-info .watch-auction .watch-lots .lot-item .lot-info-bottom .info-btns {
    justify-content: start;
    align-items: start; } }

@media (max-width: 550px) {
  .watchlist-wrapper .watch-lots .lot-item .lot-info .lot-info-bottom {
    justify-content: start;
    align-items: start; } }

@media (max-width: 550px) {
  .watchlist-wrapper .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
    justify-content: start;
    align-items: start; } }

.auctions-controls, .watchlist-controls {
  padding: 10px 0px 10px 0px;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6; }
  .auctions-controls .filters-heading, .watchlist-controls .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center; }
  .auctions-controls .auctions-filters-wrapper, .watchlist-controls .auctions-filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 550px) {
      .auctions-controls .auctions-filters-wrapper, .watchlist-controls .auctions-filters-wrapper {
        flex-direction: row; } }
  .auctions-controls .btn, .watchlist-controls .btn {
    margin-top: 10px !important; }

.auction-info, .watch-auction {
  display: flex;
  flex: 2;
  flex-direction: column; }
  .auction-info .auction-info-top, .auction-info .auction-info-middle, .watch-auction .auction-info-top, .watch-auction .auction-info-middle {
    border-bottom: 1px solid #c6c6c6; }
  .auction-info .auction-info-top, .watch-auction .auction-info-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 12px 12px 25px; }
    @media (min-width: 550px) {
      .auction-info .auction-info-top, .watch-auction .auction-info-top {
        flex-direction: row; } }
    @media (max-width: 550px) {
      .auction-info .auction-info-top, .watch-auction .auction-info-top {
        padding: 12px 12px 12px 19px; } }
    .auction-info .auction-info-top .auction-title, .auction-info .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-title, .watch-auction .auction-info-top .auction-live-date {
      margin: 0; }
    .auction-info .auction-info-top .auction-title, .watch-auction .auction-info-top .auction-title {
      color: #000000;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 10px; }
    .auction-info .auction-info-top .auction-live-date, .auction-info .auction-info-top .auction-closed-date, .watch-auction .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-closed-date {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 15px; }
      @media (min-width: 550px) {
        .auction-info .auction-info-top .auction-live-date, .auction-info .auction-info-top .auction-closed-date, .watch-auction .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-closed-date {
          line-height: 36px;
          margin-bottom: 0; } }
    .auction-info .auction-info-top .info-btns, .watch-auction .auction-info-top .info-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap; }
      .auction-info .auction-info-top .info-btns > *:not(:first-child), .watch-auction .auction-info-top .info-btns > *:not(:first-child) {
        margin-top: 10px; }
  .auction-info .auction-info-middle, .watch-auction .auction-info-middle {
    display: flex;
    flex-direction: column;
    min-height: 90px; }
    @media (min-width: 550px) {
      .auction-info .auction-info-middle, .watch-auction .auction-info-middle {
        flex-direction: row; } }
    .auction-info .auction-info-middle .auction-type, .watch-auction .auction-info-middle .auction-type {
      width: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ededed; }
      @media (min-width: 550px) {
        .auction-info .auction-info-middle .auction-type, .watch-auction .auction-info-middle .auction-type {
          max-width: 126px;
          border-bottom: none;
          border-right: 1px solid #ededed; } }
      .auction-info .auction-info-middle .auction-type img, .watch-auction .auction-info-middle .auction-type img {
        max-width: 100%;
        max-height: 50px; }
    .auction-info .auction-info-middle .auction-desc, .watch-auction .auction-info-middle .auction-desc {
      flex: 1;
      font-size: 12px;
      color: #5d5d5d;
      line-height: 24px;
      font-family: 'Lato';
      padding: 15px 30px 15px 8px; }
      @media (max-width: 550px) {
        .auction-info .auction-info-middle .auction-desc, .watch-auction .auction-info-middle .auction-desc {
          padding-left: 19px; } }
    .auction-info .auction-info-middle .auction-logo, .watch-auction .auction-info-middle .auction-logo {
      width: 100%;
      min-height: 75px;
      padding: 7px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #ededed; }
      @media (min-width: 550px) {
        .auction-info .auction-info-middle .auction-logo, .watch-auction .auction-info-middle .auction-logo {
          max-width: 250px;
          border-top: none;
          border-left: 1px solid #ededed; } }
      .auction-info .auction-info-middle .auction-logo img, .watch-auction .auction-info-middle .auction-logo img {
        max-width: 100%;
        max-height: 75px; }
  .auction-info .auction-info-bottom, .watch-auction .auction-info-bottom {
    margin: 20px;
    display: flex;
    flex-wrap: wrap; }
    .auction-info .auction-info-bottom > span, .watch-auction .auction-info-bottom > span {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 35px;
      padding: 0 10px;
      margin-bottom: 5px;
      border-radius: 5px;
      border: 1px solid #5d5d5d; }
      .auction-info .auction-info-bottom > span:not(:last-of-type), .watch-auction .auction-info-bottom > span:not(:last-of-type) {
        margin-right: 5px; }

.watch-auction .auction-info-top .line1, .watch-auction .auction-info-top .line2 {
  margin-left: 0 !important; }

@media (max-width: 900px) {
  .auctions-wrapper .auctions-list .auction-item {
    display: block; }
    .auctions-wrapper .auctions-list .auction-item .auction-img-container {
      min-width: 250px;
      max-width: 100%;
      border-right: none;
      border-bottom: 1px solid #c6c6c6; } }

.icon-blackened {
  filter: brightness(0); }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.back-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 231px;
  height: 44px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #000000;
  text-align: left;
  color: #030404;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: box-shadow 300ms;
  padding-left: 20px;
  text-decoration: none;
  overflow: hidden;
  user-select: none;
  padding-left: 0;
  padding-right: 20px;
  width: 208px;
  padding-right: 10px;
  flex-shrink: 0;
  margin: 0 10px 10px 0;
  justify-content: flex-start; }
  .back-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
  .back-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff; }
    .back-btn .icon-wrapper.icon-green {
      color: #1f9512 !important; }
    .back-btn .icon-wrapper.icon-success {
      background-color: #1f9512 !important; }
    .back-btn .icon-wrapper.icon-warning {
      background-color: #fac917 !important; }
    .back-btn .icon-wrapper.icon-danger {
      color: #dd2d2e !important; }
    .back-btn .icon-wrapper.icon-red {
      background-color: #dd2d2e !important; }
  .back-btn .icon-wrapper {
    border-left: 0;
    border-right: 1px solid #000000;
    border-radius: 0 5px 5px 0; }
  @media (max-width: 550px) {
    .back-btn {
      margin-right: 0;
      width: 100%; } }
  .back-btn .icon-wrapper {
    margin-right: 10px; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.details, .lot-documents {
  position: relative; }
  .details .row, .lot-documents .row {
    margin-bottom: 5px;
    border-radius: 2px; }
    .details .row .row-title-wrap, .lot-documents .row .row-title-wrap {
      margin: 0;
      background-color: #666666;
      border-bottom: 1px solid #c6c6c6; }
      .details .row .row-title-wrap .row-title, .lot-documents .row .row-title-wrap .row-title {
        padding: 10px 10px;
        margin: 0;
        font: 700 16px "Lato";
        color: white; }
    .details .row .documents-container, .lot-documents .row .documents-container {
      margin-top: 5px;
      padding: 20px 0;
      background-color: #ededed;
      border: 1px solid #c6c6c6; }
    .details .row .fields-container, .lot-documents .row .fields-container {
      margin-top: 5px;
      display: flex;
      justify-content: space-between; }
      @media (max-width: 900px) {
        .details .row .fields-container, .lot-documents .row .fields-container {
          flex-direction: column; } }
    .details .row .left-column, .details .row .right-column, .lot-documents .row .left-column, .lot-documents .row .right-column {
      border: 1px solid #c6c6c6;
      background-color: #ededed;
      width: 50%; }
      @media (max-width: 900px) {
        .details .row .left-column, .details .row .right-column, .lot-documents .row .left-column, .lot-documents .row .right-column {
          width: 100%;
          border-radius: 0 !important; } }
    .details .row .left-column, .lot-documents .row .left-column {
      margin-right: 5px; }
      @media (max-width: 900px) {
        .details .row .left-column, .lot-documents .row .left-column {
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: #c6c6c6; } }
    @media (max-width: 900px) {
      .details .row .right-column, .lot-documents .row .right-column {
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: #c6c6c6; } }
    .details .row .one-column, .lot-documents .row .one-column {
      border: 1px solid #c6c6c6;
      background-color: #ededed;
      width: 100%; }
      .details .row .one-column .option .option-line .option-value, .lot-documents .row .one-column .option .option-line .option-value {
        flex: 5; }
    .details .row .file-wrap, .lot-documents .row .file-wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 10px; }
      @media (max-width: 550px) {
        .details .row .file-wrap, .lot-documents .row .file-wrap {
          flex-direction: column; } }
      .details .row .file-wrap svg, .lot-documents .row .file-wrap svg {
        margin-left: 5px; }
      .details .row .file-wrap .description, .lot-documents .row .file-wrap .description {
        flex: 1 1; }
      .details .row .file-wrap .file-name, .lot-documents .row .file-wrap .file-name {
        flex: 1 1;
        font-weight: bold;
        margin: 0; }
      .details .row .file-wrap .view-link, .details .row .file-wrap .download-link, .lot-documents .row .file-wrap .view-link, .lot-documents .row .file-wrap .download-link {
        flex: 1 1;
        color: #030404;
        text-decoration: none; }
    .details .row .repeat-option .repeat-title-wrap, .lot-documents .row .repeat-option .repeat-title-wrap {
      margin: 0;
      background-color: white;
      border-width: 1px 0;
      border-style: solid;
      border-color: #c6c6c6; }
      .details .row .repeat-option .repeat-title-wrap .repeat-title, .lot-documents .row .repeat-option .repeat-title-wrap .repeat-title {
        padding: 10px 10px;
        margin: 0;
        color: #5d5d5d;
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        font-size: 16px;
        font-weight: 700; }
    .details .row .repeat-option .repeat-line, .lot-documents .row .repeat-option .repeat-line {
      margin-left: 10px; }
    .details .row .option .option-line, .lot-documents .row .option .option-line {
      display: flex;
      align-items: center; }
      .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px; }
        @media (max-width: 900px) {
          .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
            font-size: 12px;
            padding-top: 5px;
            padding-bottom: 5px; } }
        @media (max-width: 550px) {
          .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
            font-size: 9px; } }
      .details .row .option .option-line .option-title, .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-title, .lot-documents .row .option .option-line .option-value {
        word-break: break-word;
        margin: 0;
        padding: 5px 10px;
        color: #030404; }
        .details .row .option .option-line .option-title.empty, .details .row .option .option-line .option-value.empty, .lot-documents .row .option .option-line .option-title.empty, .lot-documents .row .option .option-line .option-value.empty {
          color: #7e7e7e; }
      .details .row .option .option-line .option-title, .lot-documents .row .option .option-line .option-title {
        flex: 1;
        min-width: 100px;
        font: 400 16px "Lato"; }
      .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-value {
        flex: 2; }
        @media (max-width: 550px) {
          .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-value {
            flex: 1; } }
        .details .row .option .option-line .option-value .paragraph, .lot-documents .row .option .option-line .option-value .paragraph {
          display: block; }
        .details .row .option .option-line .option-value a, .lot-documents .row .option .option-line .option-value a {
          text-decoration: none; }
          .details .row .option .option-line .option-value a:hover, .lot-documents .row .option .option-line .option-value a:hover {
            text-decoration: underline; }
        .details .row .option .option-line .option-value .signature-img, .lot-documents .row .option .option-line .option-value .signature-img {
          height: 100px; }
    .details .row .option .signature-icon, .lot-documents .row .option .signature-icon {
      width: 180px; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.filter-date-wrapper,
.filter-multi-wrapper,
.filter-range-wrapper {
  width: 100%;
  position: relative; }
  @media (min-width: 551px) {
    .filter-date-wrapper,
    .filter-multi-wrapper,
    .filter-range-wrapper {
      max-width: 231px;
      margin-right: 10px; } }
  .filter-date-wrapper .btn-opened,
  .filter-multi-wrapper .btn-opened,
  .filter-range-wrapper .btn-opened {
    position: relative;
    z-index: 900;
    border-color: #000;
    border-radius: 5px; }
    @media (min-width: 551px) {
      .filter-date-wrapper .btn-opened,
      .filter-multi-wrapper .btn-opened,
      .filter-range-wrapper .btn-opened {
        border-bottom: 0;
        border-radius: 5px 5px 5px 0; } }
    .filter-date-wrapper .btn-opened .icon-wrapper,
    .filter-multi-wrapper .btn-opened .icon-wrapper,
    .filter-range-wrapper .btn-opened .icon-wrapper {
      border-left: 1px solid #000;
      border-bottom: 1px solid #000; }

.filter-date-inner,
.filter-multi-inner,
.filter-range-inner {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 15px;
  background-color: #fff;
  font-family: Lato;
  border-radius: 5px;
  border: 1px solid #000;
  z-index: 99; }
  @media (min-width: 551px) {
    .filter-date-inner,
    .filter-multi-inner,
    .filter-range-inner {
      width: auto; } }
  @media (min-width: 1250px) {
    .filter-date-inner,
    .filter-multi-inner,
    .filter-range-inner {
      flex-direction: row;
      justify-content: space-between; } }
  .filter-date-inner > div:first-child,
  .filter-multi-inner > div:first-child,
  .filter-range-inner > div:first-child {
    text-align: center; }
    @media (min-width: 1250px) {
      .filter-date-inner > div:first-child,
      .filter-multi-inner > div:first-child,
      .filter-range-inner > div:first-child {
        text-align: left; } }
  .filter-date-inner.calendars-right-aligned,
  .filter-multi-inner.calendars-right-aligned,
  .filter-range-inner.calendars-right-aligned {
    left: auto;
    right: 0; }
    @media (min-width: 551px) {
      .filter-date-inner.calendars-right-aligned,
      .filter-multi-inner.calendars-right-aligned,
      .filter-range-inner.calendars-right-aligned {
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
        right: 1px; } }
  .filter-date-inner h3, .filter-date-inner p,
  .filter-multi-inner h3,
  .filter-multi-inner p,
  .filter-range-inner h3,
  .filter-range-inner p {
    color: #5d5d5d;
    margin: 0; }
  .filter-date-inner h3,
  .filter-multi-inner h3,
  .filter-range-inner h3 {
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .filter-date-inner p,
  .filter-multi-inner p,
  .filter-range-inner p {
    font-size: 14px; }
  .filter-date-inner .calendars-wrapper,
  .filter-multi-inner .calendars-wrapper,
  .filter-range-inner .calendars-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 551px) {
      .filter-date-inner .calendars-wrapper,
      .filter-multi-inner .calendars-wrapper,
      .filter-range-inner .calendars-wrapper {
        flex-direction: row;
        align-items: flex-start; } }
    .filter-date-inner .calendars-wrapper.in-column,
    .filter-multi-inner .calendars-wrapper.in-column,
    .filter-range-inner .calendars-wrapper.in-column {
      flex-direction: column; }

.calendars-buttons,
.range-buttons {
  display: flex;
  justify-content: center; }
  .calendars-buttons .btn:first-child,
  .range-buttons .btn:first-child {
    margin-right: 10px; }

@media (min-width: 1250px) {
  .filter-date-inner {
    width: 873px; } }

.calendars-controls {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 14px;
  color: #5d5d5d; }

.calendars-controls label,
.filter-multi-options label {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between; }
  .calendars-controls label span,
  .filter-multi-options label span {
    cursor: pointer; }

.calendars-controls .filter-checkbox,
.filter-multi-options .filter-checkbox {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #000; }
  .calendars-controls .filter-checkbox.active,
  .filter-multi-options .filter-checkbox.active {
    background-color: #1f9512; }

.calendars-controls input,
.filter-multi-options input {
  display: none; }

.DayPicker-wrapper {
  font-family: Lato; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell] .DayPicker-Filled {
    border: 1px solid #464646;
    border-right: 0;
    border-bottom: 0; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day:not([role="gridcell"]) + .DayPicker-Day .DayPicker-Filled {
    border-top-left-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day[role="gridcell"]:first-of-type .DayPicker-Filled {
    border-top-left-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day[role="gridcell"]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646;
    border-top-right-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role="gridcell"] .DayPicker-Filled {
    border-bottom: 1px solid #464646; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role="gridcell"]:first-of-type .DayPicker-Filled {
    border-bottom-left-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role="gridcell"]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646;
    border-bottom-right-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role="gridcell"] {
    position: relative;
    padding: 0;
    height: 33px;
    width: 33px; }
    .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role="gridcell"] .DayPicker-Filled {
      display: flex;
      width: 104%;
      height: 102%;
      justify-content: center;
      align-items: center;
      margin: 0; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--today {
    font-weight: inherit; }
    .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
      color: inherit; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover .DayPicker-Filled {
    background-color: #fac917; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) .DayPicker-Filled {
    background-color: #1f9512;
    color: #fff; }

.DayPicker-Caption div {
  text-align: center; }

.DayPicker-wrapper .DayPicker-NavButton {
  height: 30px;
  width: 30px;
  top: 8px;
  border-radius: 3px;
  border: 1px solid #000;
  background-color: #c6c6c6;
  color: #fff; }
  .DayPicker-wrapper .DayPicker-NavButton--prev {
    left: 1rem;
    right: auto;
    margin-right: 0;
    background-image: url(./assets/img/arrow-left.png); }
  .DayPicker-wrapper .DayPicker-NavButton--next {
    right: 1rem;
    background-image: url(./assets/img/arrow-right.png); }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
@media (min-width: 551px) {
  .filter-multi-wrapper-sm,
  .filter-range-wrapper-sm {
    max-width: 120px; }
  .filter-multi-wrapper-md,
  .filter-range-wrapper-md {
    max-width: 155px; }
  .filter-multi-wrapper-lg,
  .filter-range-wrapper-lg {
    max-width: 230px; } }

.filter-multi-inner {
  min-width: 100%;
  flex-direction: column !important; }
  @media (min-width: 550px) {
    .filter-multi-inner {
      border-top-left-radius: 0; } }
  .filter-multi-inner-right {
    left: auto;
    right: 0; }
    @media (min-width: 550px) {
      .filter-multi-inner-right {
        border-top-left-radius: 5px;
        border-top-right-radius: 0; } }
  @media (min-width: 1250px) {
    .filter-multi-inner {
      min-width: auto; } }
  .filter-multi-inner .filter-multi-options {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1250px) {
      .filter-multi-inner .filter-multi-options {
        flex-direction: row;
        justify-content: space-between; } }
  .filter-multi-inner .filter-multi-buttons {
    display: flex;
    justify-content: center; }
  .filter-multi-inner ul {
    display: flex;
    flex: 1;
    min-width: 200px;
    flex-direction: column; }
    @media (min-width: 1250px) {
      .filter-multi-inner ul:not(:last-child) {
        margin-right: 25px; } }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.filter-kind-component {
  display: flex;
  user-select: none; }
  .filter-kind-component .filter-kind-dropdown {
    position: relative; }
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 231px;
      height: 44px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #000000;
      text-align: left;
      color: #030404;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: box-shadow 300ms;
      padding-left: 20px;
      text-decoration: none;
      overflow: hidden;
      user-select: none;
      margin: 0;
      padding-left: 10px;
      min-width: 240px; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 44px;
        height: 100%;
        font-size: 22px;
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #000000;
        color: #fff; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-green {
          color: #1f9512 !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-success {
          background-color: #1f9512 !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-warning {
          background-color: #fac917 !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-danger {
          color: #dd2d2e !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-red {
          background-color: #dd2d2e !important; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
      @media (max-width: 550px) {
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn {
          width: 100%; } }
      @media (min-width: 551px) {
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:not(:last-of-type) {
          margin-right: 10px; } }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover:not(.btn-opened) {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5); }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover:not(.btn-opened) .icon-wrapper {
          background-color: #fac917; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
        transition: 200ms;
        color: #5d5d5d; }
        @media (min-width: 550px) and (max-width: 900px) {
          .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
            margin-left: 10px; } }
      @media (min-width: 550px) {
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-sm {
          max-width: 120px; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-md {
          max-width: 155px; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-lg {
          max-width: 230px; } }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:not(.btn-opened) {
        border-color: #1f9512; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper,
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper {
        background-color: #1f9512;
        border-color: #1f9512; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper > svg,
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper > img,
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper > svg,
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper > img {
          filter: invert(100) brightness(200); }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 44px;
        height: 100%;
        font-size: 30px;
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #000000; }
  .filter-kind-component .dropdown-choices {
    display: none;
    position: absolute;
    min-width: 240px;
    z-index: 100; }
    .filter-kind-component .dropdown-choices .dropdown-choice {
      display: flex;
      background-color: white;
      padding: 15px 10px;
      justify-content: space-between;
      border: 1px solid #c6c6c6;
      font-family: Lato;
      font-size: 16px;
      cursor: pointer; }
      .filter-kind-component .dropdown-choices .dropdown-choice:hover {
        background-color: #e1e1e1; }
      .filter-kind-component .dropdown-choices .dropdown-choice:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
      .filter-kind-component .dropdown-choices .dropdown-choice:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; }
      .filter-kind-component .dropdown-choices .dropdown-choice.selected {
        background-color: #cccccc; }
  .filter-kind-component.opened .dropdown-choices {
    display: block; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.filter-range-inner {
  flex-direction: column;
  width: 80vw;
  max-width: 824px; }
  .filter-range-inner.in-column {
    width: auto; }
    @media (max-width: 550px) {
      .filter-range-inner.in-column {
        width: 100%; } }
  @media (min-width: 550px) {
    .filter-range-inner {
      border-top-left-radius: 0; } }
  .filter-range-inner-right {
    left: auto;
    right: 0; }
    @media (min-width: 550px) {
      .filter-range-inner-right {
        border-top-left-radius: 5px;
        border-top-right-radius: 0; } }
  .filter-range-inner .slider-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 20px 0 30px;
    justify-content: center;
    align-items: center; }
    .filter-range-inner .slider-wrapper.in-column {
      flex-direction: column; }
    .filter-range-inner .slider-wrapper span {
      width: 120px;
      cursor: text;
      min-width: 110px; }
      .filter-range-inner .slider-wrapper span.slider-counter {
        max-width: 150px;
        display: inline-block;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 15px;
        border-radius: 5px;
        border: 1px solid #464646; }
        .filter-range-inner .slider-wrapper span.slider-counter-left {
          margin-bottom: 0;
          margin-right: 10px; }
          .filter-range-inner .slider-wrapper span.slider-counter-left.in-column {
            margin-bottom: 20px;
            margin-right: 0; }
        .filter-range-inner .slider-wrapper span.slider-counter-right {
          margin-top: 0;
          margin-left: 10px; }
          .filter-range-inner .slider-wrapper span.slider-counter-right.in-column {
            margin-top: 20px;
            margin-left: 0; }
      .filter-range-inner .slider-wrapper span input[type=number]::-webkit-inner-spin-button,
      .filter-range-inner .slider-wrapper span input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .filter-range-inner .slider-wrapper span input.weightFilter {
        width: 52%;
        min-width: 40px;
        float: left;
        margin-right: 3px;
        text-align: right;
        font-family: Lato;
        font-size: 17px;
        border-width: 0;
        color: #5d5d5d; }
      .filter-range-inner .slider-wrapper span input.weightSecondField {
        width: 52%;
        min-width: 56px;
        float: left;
        text-align: right;
        font-family: Lato;
        font-size: 17px;
        border-width: 0;
        color: #5d5d5d; }
      .filter-range-inner .slider-wrapper span .text-kg {
        font-family: Lato;
        color: #5d5d5d;
        font-size: 17px;
        text-align: left; }
  .filter-range-inner .rc-slider {
    flex: 1;
    max-width: 80%;
    margin: -7px 25px 0 25px; }
    .filter-range-inner .rc-slider-rail {
      height: 12px;
      background-color: #fff;
      border: 1px solid #000; }
    .filter-range-inner .rc-slider-track {
      height: 12px;
      background-color: #1f9512;
      border: 1px solid #000; }
    .filter-range-inner .rc-slider-handle {
      width: 40px;
      height: 24px;
      margin-top: -5px;
      margin-left: -20px;
      border-radius: 5px;
      border: 1px solid #000;
      background-color: #c6c6c6;
      transform: none !important; }
    .filter-range-inner .rc-slider-handle-1:after, .filter-range-inner .rc-slider-handle-2:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      color: #000; }
    .filter-range-inner .rc-slider-handle-1:after {
      content: 'Min.'; }
    .filter-range-inner .rc-slider-handle-2:after {
      content: 'Max.'; }

.slider-wrapper {
  overflow: hidden; }
  .slider-wrapper.age {
    flex-direction: column; }
    .slider-wrapper.age .slider-wrapper-left, .slider-wrapper.age .slider-wrapper-right {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .slider-wrapper.age .slider-wrapper-left .slider-counter-age, .slider-wrapper.age .slider-wrapper-right .slider-counter-age {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 5px; }
        .slider-wrapper.age .slider-wrapper-left .slider-counter-age .inputAge, .slider-wrapper.age .slider-wrapper-right .slider-counter-age .inputAge {
          text-align: center; }
        .slider-wrapper.age .slider-wrapper-left .slider-counter-age .text-mounth, .slider-wrapper.age .slider-wrapper-right .slider-counter-age .text-mounth {
          width: 100%;
          min-width: auto;
          text-align: center; }

.slider-wrapper .range-age {
  margin-top: 15px;
  margin-bottom: 25px; }

.slider-wrapper .slider-wrapper-left,
.slider-wrapper .slider-wrapper-right {
  display: flex;
  flex: 0.3;
  margin-bottom: 10px; }
  .slider-wrapper .slider-wrapper-left .slider-text,
  .slider-wrapper .slider-wrapper-right .slider-text {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #5d5d5d;
    font-size: 18px; }
  .slider-wrapper .slider-wrapper-left span,
  .slider-wrapper .slider-wrapper-right span {
    width: 100%;
    cursor: text;
    min-width: 110px; }
    .slider-wrapper .slider-wrapper-left span.slider-counter-age:not(:root:root),
    .slider-wrapper .slider-wrapper-right span.slider-counter-age:not(:root:root) {
      line-height: 21px; }
    .slider-wrapper .slider-wrapper-left span.slider-counter-age,
    .slider-wrapper .slider-wrapper-right span.slider-counter-age {
      flex-direction: row;
      max-width: 75px;
      height: 60px;
      align-items: stretch;
      display: block;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 5px;
      border: 1px solid #464646; }
      .slider-wrapper .slider-wrapper-left span.slider-counter-age-left,
      .slider-wrapper .slider-wrapper-right span.slider-counter-age-left {
        margin-bottom: 0;
        margin-right: 0; }
        .slider-wrapper .slider-wrapper-left span.slider-counter-age-left.in-column,
        .slider-wrapper .slider-wrapper-right span.slider-counter-age-left.in-column {
          margin-bottom: 0;
          margin-right: 0; }
      .slider-wrapper .slider-wrapper-left span.slider-counter-age-right,
      .slider-wrapper .slider-wrapper-right span.slider-counter-age-right {
        margin-top: 0;
        margin-left: 0; }
        .slider-wrapper .slider-wrapper-left span.slider-counter-age-right.in-column,
        .slider-wrapper .slider-wrapper-right span.slider-counter-age-right.in-column {
          margin-top: 0;
          margin-left: 0; }
    .slider-wrapper .slider-wrapper-left span input,
    .slider-wrapper .slider-wrapper-right span input {
      width: 40%;
      min-width: 40px;
      margin-right: 3px;
      float: left;
      border-width: 0;
      color: #5d5d5d;
      font-size: 18px;
      text-align: right; }
    .slider-wrapper .slider-wrapper-left span .text-mounth,
    .slider-wrapper .slider-wrapper-right span .text-mounth {
      font-family: Lato;
      color: #5d5d5d;
      font-size: 18px;
      text-align: left; }
