.bordered-container {
  border: 1px solid #c6c6c6;
  margin-top: 5px;
}
.optiweigh-container {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #ededed;

  .optiWeighLogo {
    top:32%;
    left: 23%;
    position: absolute;
    height: 45%;
    opacity: 0.15;
    object-fit: scale-down;
    width: 50%;

    &.middle {
      left: 20%;
    }

    &.small {
      left: 15%;
    }

    @media (max-width: 400px) {
      display: none;
    }
  }
}

.individual-assessment-container {
  margin-top: 5px;
  border: 1px solid #c6c6c6;
  background-color: #ededed;
}

.divider {
  width: 100%;
  height: 1px;
  background: #CACACA;
}