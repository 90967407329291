/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.media-carousel {
  position: relative;
  width: 100%;
  white-space: nowrap;
  margin: 5px 0; }
  .media-carousel .nav-prev, .media-carousel .nav-next {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 25% !important;
    height: 50%;
    display: flex;
    align-items: center;
    user-select: none;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    cursor: pointer; }
    .media-carousel .nav-prev.active, .media-carousel .nav-next.active {
      opacity: 1;
      pointer-events: all;
      z-index: 50; }
    .media-carousel .nav-prev.active:hover, .media-carousel .nav-next.active:hover {
      opacity: 1 !important; }
    .media-carousel .nav-prev .icon-wrapper, .media-carousel .nav-next .icon-wrapper {
      font-size: 25px;
      color: #fff; }
  .media-carousel .nav-prev {
    left: 10px;
    z-index: 9; }
  .media-carousel .nav-next {
    top: 0;
    right: 10px;
    justify-content: flex-end; }
  .media-carousel .media-wrap {
    margin: 0;
    overflow: hidden;
    user-select: none; }
    .media-carousel .media-wrap .media-area {
      position: relative;
      transition: all 0.5s; }
      .media-carousel .media-wrap .media-area label {
        cursor: pointer; }
      .media-carousel .media-wrap .media-area .no-image .file-input {
        display: none; }
      .media-carousel .media-wrap .media-area .carousel-item {
        cursor: pointer;
        width: 320px;
        height: 213px;
        max-width: 100%;
        margin-right: 3px;
        display: inline-block;
        position: relative; }
        @media (max-width: 900px) {
          .media-carousel .media-wrap .media-area .carousel-item {
            width: 240px;
            height: 180px; } }
        .media-carousel .media-wrap .media-area .carousel-item.with-title {
          height: 235px; }
        .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper {
          height: 100%;
          position: relative; }
          .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper .carousel-img {
            height: unset !important;
            width: unset !important;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; }
            .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper .carousel-img.thumbnail {
              height: 100% !important;
              width: 100% !important;
              object-fit: fill; }
            .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper .carousel-img.video-thumbnail {
              height: 100% !important;
              width: 100% !important;
              object-fit: cover; }
          .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper svg {
            position: absolute;
            top: calc(50% - 75px / 2);
            left: calc(50% - 75px / 2);
            height: 75px;
            width: 75px;
            color: #2e2e2e;
            z-index: 2; }
        .media-carousel .media-wrap .media-area .carousel-item .remove {
          position: absolute;
          top: 15px;
          left: 15px;
          z-index: 2; }
        .media-carousel .media-wrap .media-area .carousel-item .set-main {
          padding: 3px 5px;
          background: #808080;
          border-radius: 5px;
          font: 400 16px "Lato";
          color: #fff;
          position: absolute;
          bottom: 15px;
          right: 15px;
          z-index: 2; }
          .media-carousel .media-wrap .media-area .carousel-item .set-main .inner-line {
            display: flex;
            align-items: center; }
            .media-carousel .media-wrap .media-area .carousel-item .set-main .inner-line .set-main-text {
              margin: 0 0 0 5px; }
        .media-carousel .media-wrap .media-area .carousel-item .carousel-img, .media-carousel .media-wrap .media-area .carousel-item .carousel-video {
          display: block; }
        .media-carousel .media-wrap .media-area .carousel-item .date {
          padding: 3px 5px;
          border-radius: 5px;
          font: 400 16px "Lato";
          color: #fff;
          position: absolute;
          bottom: 15px;
          left: 15px;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #808080; }
        .media-carousel .media-wrap .media-area .carousel-item .image-loader-spinner {
          display: flex;
          height: 100%;
          position: absolute;
          width: 100%;
          top: 0;
          align-items: center;
          justify-content: center;
          z-index: 100; }
        .media-carousel .media-wrap .media-area .carousel-item .carousel-img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 1; }
        .media-carousel .media-wrap .media-area .carousel-item .video-title {
          display: flex;
          padding: 5px;
          justify-content: center;
          text-align: center;
          white-space: normal; }
        .media-carousel .media-wrap .media-area .carousel-item .carousel-video {
          width: 100%;
          height: 100%;
          margin: auto;
          object-fit: initial; }
          .media-carousel .media-wrap .media-area .carousel-item .carousel-video.with-title {
            height: 80%; }
        .media-carousel .media-wrap .media-area .carousel-item .video-prewiev {
          object-fit: contain; }
        .media-carousel .media-wrap .media-area .carousel-item .spinner {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5; }
          .media-carousel .media-wrap .media-area .carousel-item .spinner.hidden {
            display: none; }
  .media-carousel.preview {
    height: 100%;
    margin: 0; }
    .media-carousel.preview .media-wrap {
      height: 100%;
      margin: 0; }
      .media-carousel.preview .media-wrap .media-area {
        height: 100%; }
        .media-carousel.preview .media-wrap .media-area .carousel-item {
          width: 100%;
          height: 100%;
          margin-right: 0px; }
          @media (max-width: 900px) {
            .media-carousel.preview .media-wrap .media-area .carousel-item {
              width: 100% !important; } }
          .media-carousel.preview .media-wrap .media-area .carousel-item .carousel-img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
    .media-carousel.preview .nav-prev {
      background: transparent;
      left: 5px; }
    .media-carousel.preview .nav-next {
      background: transparent;
      right: 5px; }

.carousel-wrap {
  width: 100%;
  height: 100%; }

.dots-wrap {
  padding-bottom: 12px;
  text-align: center; }
  .dots-wrap .dot {
    width: 10px;
    height: 10px;
    margin-right: 9px;
    background: #2e2e2e;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer; }
    .dots-wrap .dot.active {
      background: #8e8e8e; }

canvas {
  width: 320px;
  height: 190px;
  position: absolute;
  top: 20px; }

div[data-rbd-droppable-context-id] {
  position: relative; }

.order {
  padding: 3px 5px;
  background: #808080;
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  top: 24px;
  right: 10px;
  font: 400 16px "Lato";
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }
  .order .control-icon {
    width: 20px;
    height: 20px;
    border-radius: 2px; }
  .order .text {
    background: #808080;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px; }
