@import "./variables.scss";

html {
  height: 100%;
}
body {
  min-height: 100%;
}

.inner-container {
  max-width: 1280px;
  position: relative;
  background-color: white;
  width: 100%;
  min-height: 1020px;
  margin: auto;
  background-color: #ededed;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);

  padding: 40px 50px;
  @media (max-width: $medium-width) {
    padding: 25px 10px;
  }
}

.body-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 40px 5px;
  background-color: #cccccc;
  clear: both;
  .inner-container {
    position: relative;
    background-color: white;
    max-width: 1280px;
    width: 100%;
    min-height: 1020px;
    margin: auto;
    background-color: #ededed;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);

    padding: 40px 50px;
    @media (max-width: $medium-width) {
      padding: 25px 10px;
    }
  }
  @media (max-width: $medium-width) {
    padding: 20px 0;
  }
}

.no-image {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: grey;
  width: 100%;
  height: 100%;
  font: normal 16px 'Lato';
  color: #fff;
  &:before {
    content: '';
    background: url("./assets/img/icon_photo.png") no-repeat;
    background-size: cover;
    display: flex;
    width: 53px;
    height: 40px;
    margin-bottom: 10px;
  }
  &.cattle:before {
    background: url("./assets/img/cow.png") no-repeat;
  }
  &.sheep:before {
    background: url("./assets/img/sheep.png") no-repeat;
  }
  &.goat:before {
    background: url("./assets/img/goat-image-middle.png") no-repeat;
  }
}

.separator {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #c6c6c6;
  margin: 0;
}

.app-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  &.area {
    position: absolute;
  }
  background-color: rgba(255,255,255,0.7);
  .app-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.area {
      position: absolute;
    }
  }
}

.remove {
  width: 20px;
  height: 20px;
  background: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  &:after {
    width: 20px;
    height: 20px;
    content: '';
    background: url("./assets/img/closebutton.png") no-repeat;
    background-size: cover;
    display: block;
  }
}

button, label {
  &:focus {
    box-shadow: 0 0 2px 2px #a3a3b1;
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.fg-header {
  @include auction-title-header;
}

.fg-sub-header {
  @include auction-title-sub-header;
}

.fg-paragraph {
  color: #030404;
  font: 400 16px Lato;
}
