/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.lot-wrapper {
  position: relative; }
  @media (max-width: 900px) {
    .lot-wrapper .lot-info .title-block {
      justify-content: center;
      align-items: center; } }
  .lot-wrapper .top-btns {
    display: flex; }
    .lot-wrapper .top-btns.results {
      flex-wrap: wrap; }
      .lot-wrapper .top-btns.results .back-btn {
        width: 228px; }
        @media (max-width: 550px) {
          .lot-wrapper .top-btns.results .back-btn {
            width: 100%; } }
      .lot-wrapper .top-btns.results .watching-btn {
        width: 228px;
        margin-bottom: 10px; }
        @media (max-width: 550px) {
          .lot-wrapper .top-btns.results .watching-btn {
            width: 100%; } }
      .lot-wrapper .top-btns.results.results-flex {
        flex-wrap: nowrap; }
        .lot-wrapper .top-btns.results.results-flex .back-btn {
          min-width: 180px;
          width: auto;
          flex-grow: 1; }
          @media (max-width: 550px) {
            .lot-wrapper .top-btns.results.results-flex .back-btn {
              width: 100%; } }
        .lot-wrapper .top-btns.results.results-flex .watching-btn {
          min-width: 140px;
          width: auto;
          flex-grow: 1; }
          @media (max-width: 550px) {
            .lot-wrapper .top-btns.results.results-flex .watching-btn {
              width: 100%; } }
        @media (max-width: 900px) {
          .lot-wrapper .top-btns.results.results-flex {
            flex-wrap: wrap; } }
  .lot-wrapper .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    width: 228px;
    margin: 0 10px 10px 0; }
    .lot-wrapper .btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
    .lot-wrapper .btn .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 44px;
      height: 100%;
      font-size: 22px;
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #000000;
      color: #fff; }
      .lot-wrapper .btn .icon-wrapper.icon-green {
        color: #1f9512 !important; }
      .lot-wrapper .btn .icon-wrapper.icon-success {
        background-color: #1f9512 !important; }
      .lot-wrapper .btn .icon-wrapper.icon-warning {
        background-color: #fac917 !important; }
      .lot-wrapper .btn .icon-wrapper.icon-danger {
        color: #dd2d2e !important; }
      .lot-wrapper .btn .icon-wrapper.icon-red {
        background-color: #dd2d2e !important; }
    @media (max-width: 550px) {
      .lot-wrapper .btn {
        width: 100%;
        margin: 0 0 10px 0; } }
    .lot-wrapper .btn.btn-flex {
      width: auto;
      flex-grow: 1; }
      @media (max-width: 900px) {
        .lot-wrapper .btn.btn-flex {
          width: 228px; } }
      @media (max-width: 550px) {
        .lot-wrapper .btn.btn-flex {
          width: 100%; } }
      .lot-wrapper .btn.btn-flex .btn-title {
        display: inline-block; }
  .lot-wrapper .lot-number {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400; }
  .lot-wrapper .lot-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px; }
    .lot-wrapper .lot-menu .auction-title, .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
      margin: 0; }
    .lot-wrapper .lot-menu .auction-title {
      color: #000000;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 15px; }
    .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 24px;
      font-weight: 400;
      margin: 0; }
      @media (max-width: 550px) {
        .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
          font-size: 16px; } }
    .lot-wrapper .lot-menu .left .left {
      order: 2; }
    .lot-wrapper .lot-menu .left .right {
      order: 1;
      border-left: 0;
      border-right: 1px solid #c6c6c6; }
    .lot-wrapper .lot-menu .left .back-img:before {
      transform: rotate(360deg) !important; }
  .lot-wrapper .lot-number {
    margin: 0 0 20px 0; }
  .lot-wrapper .pdf-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .lot-wrapper .pdf-buttons .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 31px;
      font-family: 'Myriad Pro';
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
      transition: background-color 0.2s;
      margin: 0;
      user-select: none;
      text-decoration: none;
      margin-bottom: 20px;
      margin-right: 15px;
      color: #333333;
      background-color: #cccccc; }
      .lot-wrapper .pdf-buttons .btn:hover {
        background-color: #aeaeae; }
      .lot-wrapper .pdf-buttons .btn.hidden {
        display: none; }
  .lot-wrapper .lot-info {
    position: relative;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    padding: 0 50px;
    margin-top: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background: white;
    font-family: "Lato"; }
    .lot-wrapper .lot-info .lot-info-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0 -50px;
      height: 59px;
      border-radius: 5px 5px 0 0;
      background-color: #2e2e2e;
      color: white;
      font-size: 32px;
      padding: 0 17px; }
      @media (max-width: 900px) {
        .lot-wrapper .lot-info .lot-info-top {
          font-size: 24px;
          margin: 0 -10px; } }
      @media (max-width: 550px) {
        .lot-wrapper .lot-info .lot-info-top {
          font-size: 17px; } }
      .lot-wrapper .lot-info .lot-info-top p {
        margin: 0; }
    .lot-wrapper .lot-info .details {
      margin-top: 20px; }
    .lot-wrapper .lot-info .lot-title {
      color: #2e2e2e;
      font-size: 36px;
      margin: 26px 20px 26px 0; }
      @media (max-width: 900px) {
        .lot-wrapper .lot-info .lot-title {
          font-size: 20px; } }
  .lot-wrapper .lot-closed-state {
    font-size: 36px;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    font-weight: 100;
    color: #dd2d2e;
    margin: 26px 0 26px 30px;
    word-break: break-word; }
    @media (max-width: 900px) {
      .lot-wrapper .lot-closed-state {
        font-size: 26px; } }
    .lot-wrapper .lot-closed-state.sold {
      color: #1f9512; }
  .lot-wrapper .title-block {
    display: flex;
    justify-content: space-between; }

@media (max-width: 900px) {
  .lot-wrapper .lot-info {
    border-left: 0;
    border-right: 0;
    padding: 0 10px; } }

@media (max-width: 633px) {
  .lot-wrapper .lot-menu > .left {
    margin-bottom: 20px; } }
