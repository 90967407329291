.wrapper {
  position: relative;
  text-align: center;

  .graphContainer {
    height: 350px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 5;
  }

  .secondGraph {
    height: 350px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 4;
  }
}
